import { WorkWith } from '../shared'
import Detail from './Detail'

export const Project = () => {
	return (
		<>
			<Detail />
			<WorkWith />
		</>
	)
}

export default Project
