import { createGlobalStyle } from 'styled-components'

export const NormalizeStyled = createGlobalStyle`

*, *:before, *:after {
  box-sizing: border-box;
}

html {
  line-height: 1.15; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

a {
  background-color: transparent;
}

abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  text-decoration: underline dotted; /* 2 */
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

button,
input { /* 1 */
  overflow: visible;
}

button,
select { /* 1 */
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  white-space: normal; /* 1 */
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}


[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}


::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/*
 Add the correct display in Edge, IE 10+, and Firefox.
 */

details {
  display: block;
}

/*
 Add the correct display in all browsers.
 */

summary {
  display: list-item;
}

/**
 * Add the correct display in IE 10+.
 */

template {
  display: none;
}

/**
 * Add the correct display in IE 10.
 */

[hidden] {
  display: none;
}
`

export const GlobalStyled = createGlobalStyle`

:root {
  /* base colors */
  --cyan: #5fb4a2;
  --blue: #203a4c;
  --darkBlue: #33323d;
  --lightGrey: #fafafa;
  --grey: #eaeaeb;
  --red: #cd5c5c;
  --black: #000000;

  /* shadow */
  --smallShadow: 10px -10px 10px -5px rgba(153,153,153,0.25);

  /* positioning*/
  --containerPadding: 10%;
  --headerHeight: 9.75rem;

  /* color intentions */
  --background: var(--lightGrey);
  --buttonBackground: var(--blue);

  /* typography */
  --mainText: var(--darkBlue);
  --mutedText: var(--lightGrey);
  --headingFont: "Ibarra Real Nova", serif;
  --bodyFont: "Public Sans", sans-serif;
  --baseFontSize: 10px;
  --textExtraLarge: 3.5rem;
  --textLarge: 3.25rem;
  --textMedium: 2.5rem;
  --textRegular: 1.6rem;
  --textSmall: 1.25rem;
  --textExtraSmall: 1rem;

  --textBold: 500;

  --lightDivider: solid 1px var(--grey);
}

html {
  font-size: var(--baseFontSize);
}

body {
  background: var(--lightGrey);
  font-family: var(--bodyFont);
  font-weight: 400;
  line-height: 1.875;
  color: #333;
}

h1, h2 {
  font-size: var(--textExtraLarge)
}
h3 {
  font-size: var(--textLarge)
}
h4 {
  font-size: var(--textMedium)
}
h5 {
  font-size: var(--textRegular)
}
h6 {
  font-size: var(--textSmall)
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

p{
  font-size: var(--textRegular);
  font-weight: 400;
  line-height: 1.75;
}

a {
  text-decoration: none;
}
`
